<template>
  <div class="music-wrapper">
    <div style="height:4.5em;margin-top:.5em;">
      <template v-if="file">
        <div style="text-align:left;padding-left:42px">{{file.name}}</div>
        <div class="flex-middle">
          <Icon class="icon-btn" type="md-pause" v-if="isPlaying" @click="pause" />
          <Icon class="icon-btn" type="md-arrow-dropright-circle" v-else @click="play" />
          <div style="flex:1">
            <Slider v-model="currentPlayTime" :min="0" :max="duration" @on-change="changePlayTime"></Slider>
          </div>
          <Icon
            v-if="!contentLocked"
            class="icon-btn"
            type="md-close-circle"
            style="color:red"
            @click="delFile"
          />
        </div>
      </template>
      <template v-else>
        暂无背景音乐
      </template>
    </div>

    <p
      v-if="!contentLocked"
      style="margin-bottom:.5em"
      class="text-mute"
    >背景音频（非必传），每篇音频独立，所传音频大小限制小于20M且为mp3格式</p>
    <Upload
      v-if="!contentLocked"
      action="noAction"
      accept="audio/*"     
      :before-upload="proxyUpload"
    >
      <Button type="warning" ghost shape="circle">上传当前背景音</Button>
    </Upload>
  </div>
</template>

<script>
export default {
  props: {
    musicInfo: {
      default() {
        return {
          musicKey: "",
          musicUrl: ""
        };
      }
    },
    contentLocked: {
      default: false
    }
  },
  data() {
    return {
      file: null,
      isPlaying: false,
      currentPlayTime: 0,
      player: new Audio(),
      duration: 1
    };
  },
  methods: {
    proxyUpload(file) {
      if (file.size <= 20 << 20) {
        this.pause();
        this.musicInfo.musicFile = this.file = file;
        let url = URL.createObjectURL(file);
        this.player.src = url;
      } else {
        this.$Message.warning("文件大小不能超过20M");
      }
      return false;
    },
    pause() {
      this.player.pause();
      this.isPlaying = false;
    },
    play() {
      this.player.play();
      this.isPlaying = true;
    },
    delFile() {
      this.pause();
      this.musicInfo.musicFile = this.file = null;
    },
    changePlayTime(ms) {
      this.player.currentTime = ms / 1000;
    }
  },
  beforeMount() {
    if (this.musicInfo.musicUrl) {
      this.file = {
        name: "背景音乐.mp3"
      };
      this.player.src = this.musicInfo.musicUrl;
    }
    this.player.onloadedmetadata = e => {
      this.duration = this.player.duration * 1000;
    };
    this.player.ontimeupdate = e => {
      this.currentPlayTime = this.player.currentTime * 1000;
    };
    this.player.onended = e => {
      this.isPlaying = false;
    };
  }
};
</script>

<style scoped>
.music-wrapper {
  text-align: center;
  max-width: 800px;
  margin: auto;
}
.icon-btn {
  font-size: 24px;
  cursor: pointer;
  margin: 0 0.5em;
}
</style>